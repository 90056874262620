import { getProject } from '~/api/projects';

export default defineNuxtRouteMiddleware(async (to) => {
  const projectId = to.params.id?.toString();
  if (!projectId || isNaN(Number(projectId))) return;

  const { slug } = await getProject(projectId);

  if (slug) {
    return navigateTo(`/projects/${slug}`, { redirectCode: 301 });
  }
});
